<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form">
            <base-input class="input-group-alternative mb-3"
                        placeholder="Имя пользователя"
                        addon-left-icon="ni ni-email-83"
                        v-model="model.username">
            </base-input>

            <base-input class="input-group-alternative"
                        placeholder="Password"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="model.password">
            </base-input>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Запомнить меня</span>
            </base-checkbox>
            <div class="text-center">
              <base-button @click="login" type="info" class="my-4">Войти</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Забыли пароль?</small></a>
        </div>
        <div class="col-6 text-right">
          <!--                        <router-link to="/register" class="text-light"><small>Созать новый аккаунт</small></router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      model: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      this.$http.post('/jwt-auth/v1/token', this.model).then(res => {
        let {token} = res.data
        let time = 1200;
        this.$cookies.set('user_token', token, time + 'h')
        this.$store.commit('user/setToken', token)
        this.getUserInfo()
        this.$router.push('/dashboard')
      })
    },
    getUserInfo() {
      this.$http.get('carriercalc/v1/me').then((res) => {
        let {ID, display_name, user_email, user_login, user_registered} = res.data.data
        let user_data = {
          id: ID,
          display_name: display_name,
          user_email: user_email,
          user_login: user_login,
          user_registered: user_registered,
          user_meta: null,
        }
        this.$store.dispatch('user/setUserData', user_data).then(() => {
          //логика с метаполями
        })
      })
    }
  }
}
</script>
<style>
</style>
