<template>
  <div v-if="model">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <b-form class="pt-7">
            <h3>Основные данные</h3>
            <b-form-group label="Договор">
              <b-form-checkbox v-model="model.account_approved" name="check-button" switch>
                Подписан
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="ФИО">
              <b-form-input placeholder="ФИО" v-model="model.fio"></b-form-input>
            </b-form-group>
            <b-form-group label="Телефон (в международном формате, например +79631120333)">
              <b-form-input placeholder="Телефон" v-model.trim="model.phone"
                            v-mask="'+7 (###) ### ## ##'"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input placeholder="Email" v-model="model.email"></b-form-input>
            </b-form-group>
            <b-form-group label="День рождения">
              <b-form-input v-mask="'##.##.####'" placeholder="День рождения" v-model="model.birth_date"></b-form-input>
            </b-form-group>
            <b-button variant="success" @click="updateCarrier">Save</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BForm, BFormGroup, BFormInput, BButton, BFormCheckbox} from 'bootstrap-vue'
import {mask} from 'vue-the-mask'

export default {
  directives: {mask},
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox
  },
  data() {
    return {
      model: null,
      id: null,
      docs: {},
      dynamic_key: null
    }
  },
  methods: {
    loadingDoc(e, field) {
      this.dynamic_key = field
      let fd = new FormData()
      fd.append(`${[field]}`, e.target.files[0])
      this.docs[field] = fd
      this.documentLoader(this.docs[field], {'Content-Type': 'multipart/form-data'})
    },
    removeDoc(field) {
      this.dynamic_key = field
      this.docs[field] = '';
      this.documentLoader(this.docs, {})
    },
    getCarrierByKey(key) {
      return new Promise(resolve => {
        this.$http.get(`carriercalc/v1/admin_user/${this.id}`).then(res => {
          resolve(res.data.data.meta[key])
        })
      })
    },
    getCarrier() {
      this.$http.get(`carriercalc/v1/admin_user/${this.id}`).then(res => {
        let model = res.data.data.meta
        model.account_approved = Boolean(Number(model.account_approved))
        this.model = res.data.data.meta
      })
    },
    documentLoader(field, header) {
      this.$http.post(`carriercalc/v1/admin_user/${this.id}`, field,
          {
            headers: header
          }).then(() => {
        this.docs = {}
        this.getCarrierByKey(this.dynamic_key).then(key => {
          this.model[this.dynamic_key] = key
          this.dynamic_key = null
        })
      })
    },
    updateCarrier() {
      let model = JSON.parse(JSON.stringify(this.model))
      model.account_approved = Number(model.account_approved).toString()
      this.$http.post(`carriercalc/v1/admin_user/${this.id}`, this.model).then(() => {
        this.$router.push('/users')
      })
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getCarrier()
  }
}
</script>

<style scoped>
.img-doc {
  max-width: 300px;
  max-height: 300px;
  height: 100%;
  width: 100%;
}

.close-btn {
  left: 265px;
  color: white;
  top: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: red;
  border: none;
}

.close-btn:focus {
  outline: none !important;
}
</style>
