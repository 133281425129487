<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table
              :table-data="tableData"
              title="Заказы"
              @change-page="pagination"
              @change-sort="sorting"
              :pageCount="pageCount"
              :totalItems="perPage"
              :currentPage="filter.page"
          ></projects-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ProjectsTable from "@/views/Tables/ProjectsTable";


export default {
  name: "OrderList",
  components: {
    ProjectsTable,

  },
  data() {
    return {
      perPage: 10,
      pageCount: 1,
      tableData: [],
      search_string: null,
      filter: {
        metakey: [],
        metavalue: [],
        metacompare: [],
        per_page: 10,
        order_by: '',
        order: 'asc',
        page: 1
      },
    }
  },
  methods: {
    sorting(field, type) {
      this.filter.order_by = field
      this.filter.order = type
      this.getOrders()
    },
    pagination(item) {
      this.filter.page = item
      this.getOrders()
    },
    getOrders() {
      this.$http.get('carriercalc/v1/carrier-order-list', {
        params: this.filter
      }).then(res => {
        this.tableData = res.data.products
        this.pageCount = res.data.last_page
        // this.perPage = Number(res.data.per_page)

      })
    }
  },
  created() {
    this.getOrders()
    // this.$notify('Новое оповещение')
  }
}
</script>

