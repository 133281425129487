<template>
  <div v-if="model">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <b-form class="pt-7">
            <h3>Основные данные</h3>
            <b-form-group label="Статус курьера">
              <b-form-checkbox v-model="model.user_confirm" name="check-button" switch>
                Данные курьера <b>{{ model.user_confirm ? 'подтверждены' : 'не подтверждены' }}</b>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="ФИО">
              <b-form-input placeholder="ФИО" v-model="model.fio"></b-form-input>
            </b-form-group>
            <b-form-group label="Телефон (в международном формате, например +79631120333)">
              <b-form-input placeholder="Телефон" v-model.trim="model.phone"
                            v-mask="'+7 (###) ### ## ##'"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input placeholder="Email" v-model="model.email"></b-form-input>
            </b-form-group>
            <b-form-group label="День рождения">
              <b-form-input v-mask="'##.##.####'" placeholder="День рождения" v-model="model.birth_date"></b-form-input>
            </b-form-group>
            <h3>Паспорт и т.д</h3>
            <b-form-group label="Гражданство">
              <b-form-input placeholder="Гражданство" v-model="model.citizenship"></b-form-input>
            </b-form-group>
            <b-form-group label="Номер и серия паспорта">
              <b-form-input placeholder="Номер и серия паспорта" v-model="model.passport"></b-form-input>
            </b-form-group>
            <b-form-group label="Кем выдан">
              <b-form-input placeholder="Кем выдан" v-model="model.passport_issued"></b-form-input>
            </b-form-group>
            <b-form-group label="Дата выдачи">
              <b-form-input v-mask="'##/##/####'" placeholder="Дата выдачи" v-model="model.issued_date"></b-form-input>
            </b-form-group>
            <b-form-group label="Срок действия">
              <b-form-input v-mask="'##/##/####'" placeholder="Срок действия"
                            v-model="model.passport_validity_date"></b-form-input>
            </b-form-group>
            <b-form-group label="Код подразделения">
              <b-form-input placeholder="Код подразделения" v-model="model.department_code"></b-form-input>
            </b-form-group>
            <b-form-group label="Адрес регистрации">
              <b-form-input placeholder="Адрес регистрации" v-model="model.registration_address"></b-form-input>
            </b-form-group>
            <b-form-group label="ИНН">
              <b-form-input placeholder="ИНН" v-model="model.inn"></b-form-input>
            </b-form-group>
            <b-form-group label="№ пенсионного страхования">
              <b-form-input placeholder="№ пенсионного страхования" v-model="model.snils"></b-form-input>
            </b-form-group>
            <h3>Банковские реквизиты</h3>
            <b-form-group label="Наименование банка">
              <b-form-input placeholder="Наименование банка" v-model="model.bank_name"></b-form-input>
            </b-form-group>
            <b-form-group label="Расчетный счет">
              <b-form-input placeholder="Расчетный счет" v-model="model.checking_account"></b-form-input>
            </b-form-group>
            <b-form-group label="Номер банковской карты">
              <b-form-input placeholder="Номер банковской карты" v-model="model.bank_card"></b-form-input>
            </b-form-group>
            <h3>Документы</h3>

            <b-form-group label="Скан паспорта">
              <div class="d-block position-relative">
                <img v-if="model.passport_doc" class="img-doc" :src="model.passport_doc" alt="Скан паспорта">
                <b-button v-else @click="$refs.passport_doc.click()" variant="success">Загрузить</b-button>
                <button type="button" v-if="model.passport_doc" class="position-absolute close-btn"
                        @click.stop="removeDoc('passport_doc')">x
                </button>
                <input type="file" accept="image/*" class="d-none" ref="passport_doc"
                       @change="e => loadingDoc(e, 'passport_doc')">
              </div>
            </b-form-group><!--            скан паспорта-->
            <b-form-group label="Скан прописки">
              <div class="d-block position-relative">
                <img v-if="model.registration_place_doc" class="img-doc" :src="model.registration_place_doc"
                     alt="Скан паспорта">
                <b-button v-else @click="$refs.registration_place_doc.click()" variant="success">Загрузить</b-button>
                <button type="button" v-if="model.registration_place_doc" class="position-absolute close-btn"
                        @click.stop="removeDoc('registration_place_doc')">x
                </button>
                <input type="file" accept="image/*" class="d-none" ref="registration_place_doc"
                       @change="e => loadingDoc(e, 'registration_place_doc')">
              </div>
            </b-form-group>
            <b-form-group label="Другие документы">
              <div class="d-block position-relative">
                <img v-if="model.other_docs" class="img-doc" :src="model.other_docs" alt="Другие документы">
                <b-button v-else @click="$refs.other_docs.click()" variant="success">Загрузить</b-button>
                <button type="button" v-if="model.other_docs" class="position-absolute close-btn"
                        @click.stop="removeDoc('other_docs')">x
                </button>
                <input type="file" accept="image/*" class="d-none" ref="other_docs"
                       @change="e => loadingDoc(e, 'other_docs')">
              </div>
            </b-form-group>
            <button type="button" @click="updateCarrier">Save</button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BForm, BFormGroup, BFormInput, BButton, BFormCheckbox} from 'bootstrap-vue'
import {mask} from 'vue-the-mask'


export default {
  directives: {mask},
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox
  },
  data() {
    return {
      model: null,
      id: null,
      docs: {},
      dynamic_key: null
    }
  },
  methods: {
    loadingDoc(e, field) {
      this.dynamic_key = field
      let fd = new FormData()
      fd.append(`${[field]}`, e.target.files[0])
      this.docs[field] = fd
      this.documentLoader(this.docs[field], {'Content-Type': 'multipart/form-data'})
    },
    removeDoc(field) {
      this.dynamic_key = field
      this.docs[field] = '';
      this.documentLoader(this.docs, {})
    },
    getCarrierByKey(key) {
      return new Promise(resolve => {
        this.$http.get(`carriercalc/v1/admin_user/${this.id}`).then(res => {
          resolve(res.data.data.meta[key])
        })
      })
    },
    getCarrier() {
      this.$http.get(`carriercalc/v1/admin_user/${this.id}`).then(res => {
        let model = res.data.data.meta
        model.user_confirm = Boolean(Number(model.user_confirm))
        this.model = res.data.data.meta
      })
    },
    documentLoader(field, header) {
      this.$http.post(`carriercalc/v1/admin_user/${this.id}`, field,
          {
            headers: header
          }).then(() => {
        this.docs = {}
        this.getCarrierByKey(this.dynamic_key).then(key => {
          this.model[this.dynamic_key] = key
          this.dynamic_key = null
        })
      })
    },
    updateCarrier() {
      let model = JSON.parse(JSON.stringify(this.model))
      model.user_confirm = Number(model.user_confirm).toString()
      this.$http.post(`carriercalc/v1/admin_user/${this.id}`, this.model).then(() => {
        this.$router.push('/carriers')
      })
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getCarrier()
  }
}
</script>

<style scoped>
.img-doc {
  max-width: 300px;
  max-height: 300px;
  height: 100%;
  width: 100%;
}

.close-btn {
  left: 265px;
  color: white;
  top: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: red;
  border: none;
}

.close-btn:focus {
  outline: none !important;
}
</style>
