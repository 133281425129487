<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :thead-classes="'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th> <span class="cursor-pointer"
                     :class="current_sort == 'id' ? 'active-sort' : ''"
                     @click="changeSort('id')"
                     title="Нажмите, чтобы сортировать">№
            <i class="fa"
               v-if="current_sort == 'id'"
               :class="current_sort == 'id' && sort_direction == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
            </span></th>
          <th>Статус</th>
          <th>Маршрут</th>
          <th>Характеристики</th>
          <th><span class="cursor-pointer"
                    :class="current_sort == 'carrier_calc_total_price' ? 'active-sort' : ''"
                    @click="changeSort('carrier_calc_total_price')"
                    title="Нажмите, чтобы сортировать">Стоимость
            <i class="fa"
               v-if="current_sort == 'carrier_calc_total_price'"
               :class="current_sort == 'carrier_calc_total_price' && sort_direction == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
            </span></th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              {{ row.id }}
            </div>
          </th>

          <td>
            <badge class="badge-dot mr-4" :type="typeOfStatus(row.meta.order_status)">
              <i :class="`bg-${typeOfStatus(row.meta.order_status)}`"></i>
              <span class="status">{{ typeOfStatusText(row.meta.order_status) }}</span>
            </badge>
          </td>
          <td class="carrier-address">
            <div>
              <div class="time" v-if="Object.keys(row.meta.carrier_calc_points[0].time_factor).length">
                <span
                    v-if="row.meta.carrier_calc_points[0].time_factor.day">{{
                    row.meta.carrier_calc_points[0].time_factor.day
                  }} </span>
                <span
                    v-if="row.meta.carrier_calc_points[0].time_factor.begin_time">{{
                    row.meta.carrier_calc_points[0].time_factor.begin_time
                  }}</span>
                <span
                    v-if="row.meta.carrier_calc_points[0].time_factor.end_time">-{{
                    row.meta.carrier_calc_points[0].time_factor.end_time
                  }}</span>
              </div>
              <p>{{ row.meta.carrier_calc_points[0].address.trim() }}<span
                  v-if="row.meta.carrier_calc_points[0].porch">, под. {{
                  row.meta.carrier_calc_points[0].porch
                }}</span>
                <span
                    v-if="row.meta.carrier_calc_points[0].floor">, эт. {{
                    row.meta.carrier_calc_points[0].floor
                  }}</span><span
                    v-if="row.meta.carrier_calc_points[0].office">, офис {{
                    row.meta.carrier_calc_points[0].office
                  }}</span>
              </p>
            </div>
            <div>
              <div class="time" v-if="Object.keys(row.meta.carrier_calc_points[1].time_factor).length">
                <span
                    v-if="row.meta.carrier_calc_points[1].time_factor.day">{{
                    row.meta.carrier_calc_points[1].time_factor.day
                  }} </span>
                <span
                    v-if="row.meta.carrier_calc_points[1].time_factor.begin_time">{{
                    row.meta.carrier_calc_points[1].time_factor.begin_time
                  }}</span>
                <span
                    v-if="row.meta.carrier_calc_points[1].time_factor.end_time">-{{
                    row.meta.carrier_calc_points[1].time_factor.end_time
                  }}</span>
              </div>
              <p>{{ row.meta.carrier_calc_points[1].address.trim() }}<span
                  v-if="row.meta.carrier_calc_points[1].porch">, под. {{
                  row.meta.carrier_calc_points[1].porch
                }}</span>
                <span
                    v-if="row.meta.carrier_calc_points[1].floor">, эт. {{
                    row.meta.carrier_calc_points[1].floor
                  }}</span><span
                    v-if="row.meta.carrier_calc_points[1].office">, офис {{
                    row.meta.carrier_calc_points[1].office
                  }}</span>
              </p>
            </div>
          </td>
          <td class="badges-area">
            <badge v-if="row.meta.carrier_calc_package_type" rounded type="info">
              <span>{{ row.meta.carrier_calc_package_type }}</span>
            </badge>
            <badge v-if="row.meta.carrier_calc_transport" rounded type="warning">
              <span>{{ row.meta.carrier_calc_transport }}</span>
            </badge>
            <badge rounded type="primary">
              <span>{{row.meta.carrier_calc_payment_type == 'cod' ? 'Оплата наличными' : 'Оплачено'}}</span>
            </badge>
          </td>

          <td>
            <div class="d-flex align-items-center">
             {{row.meta.carrier_calc_total_price}} руб.
            </div>
          </td>
          <td class="text-right">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </template>
            </base-dropdown>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end">
      <base-pagination
          :value="currentPage"
          @input="changePage"
          :page-count="pageCount"
          :per-page="perPage"
          :total="totalItems"
      ></base-pagination>
    </div>

  </div>
</template>
<script>
export default {
  name: 'projects-table',
  props: {
    title: String,
    tableData: {
      type: Array,
      default: () => []
    },
    pageCount: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalItems: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      sort_direction: 'asc',
      current_sort: null

    }
  },
  methods: {
    typeOfStatus(status) {
      let color = null
      switch (status) {
        case 'delivered':
          color = 'success'
          break;
        case 'damaged':
          color = 'warning'
          break;
        case 'taken':
          color = 'danger'
          break;
        default:
          color = 'info'
      }
      return color
    },
    typeOfStatusText(status) {
      let text = null
      switch (status) {
        case 'delivered':
          text = 'Доставлено'
          break;
        case 'damaged':
          text = 'Поврежден'
          break;
        case 'taken':
          text = 'У курьера'
          break;
        default:
          text = 'Новый'
      }
      return text
    },
    changePage(page) {
      this.$emit('change-page', page)
    },
    changeSort(field) {
      if (field == this.current_sort) {
        this.sort_direction = this.sort_direction == 'asc' ? 'desc' : 'asc'
      }
      this.current_sort = field
      this.$emit('change-sort', field, this.sort_direction)

    }
  }
}
</script>
<style>
</style>
